<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
              <Detail/>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue"
import Detail from "@/components/Cuenta/Detail.vue"
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue"
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import Footer from '@/components/Footer.vue'
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
    setup(){
        return {}
    },
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral,
        Footer,
        Detail
    }
}
</script>