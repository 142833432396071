<template>
    <Loading :isLoading="isLoading" />
    <div class="detail-container md:flex md:h-full">

        <!-- Card -->
        <div :class="{'md:h-80': category=='ahorro' || category=='credito', 'md:h-96': category!='ahorro' && category!='credito', 'grid-rows-3': category=='vida-simple'}" class="grid w-auto md:w-full h-56 md:mx-10 mb-4 mx-4 mt-2 md:mt-8 px-3 py-4 border rounded bg-white card-shadow justify-center md:p-8">
            <div :class="{'mb-5': category != 'vida-simple' && category != 'accidentes-enfermedades'}" class="text-center mb-5">
                <p class="text-xl md:text-3xl font-normal md:font-normal text-black mb-5 detail-title">{{ (category)=='ahorro'? 'Ahorro': (category=='vida-simple')? 'Vida Simple': (category=='credito')? 'Crédito': (category=='accidentes-enfermedades')? 'Accidentes y Enfermedades': (category=='inscripcion')? 'Inscripción': '' }}</p>
                <p v-if="category != 'vida-simple' && category != 'accidentes-enfermedades' && detail.current_balance_cents" class="text-xl md:mt-10 md:mb-2 font-semibold md:font-bold text-black detail-currency"> {{ $filters.formatCurrencyWithDecimal(detail.current_balance_cents) }}</p>
                <p v-if="category != 'vida-simple' && category != 'accidentes-enfermedades'" class="text-sm md:text-lg md:font-light font-light text-gray-400 detail-subtitle">{{ category != "inscripcion"? "Total ahorrado": "Importe pendiente"}}</p>
            </div>
            <div v-if="category != 'vida-simple' && category != 'accidentes-enfermedades'" class="text-center mb-5">
                <p v-if="detail.amount_cents" class="text-xl md:mb-2 font-semibold md:font-bold text-black detail-currency"> {{ $filters.formatCurrencyWithDecimal(detail.amount_cents) }}</p>
                <p class="text-sm md:text-lg md:font-light font-light text-gray-400 detail-subtitle">Aporte quincenal</p>
            </div>

            <!--Input contract policy-->
            <div v-if="category=='accidentes-enfermedades' || category=='vida-simple'" class="w-full grid grid-cols-1 mb-3 text-center px-1 py-3 md:py-6 block">
                <router-link :to="`/cuenta/cotizacion/${category}/bienvenida`" class="btn-contract-policy border-2 rounded px-16 py-3 coursor-pointer md:w-full">Contratar Póliza</router-link>
            </div>
            <!--End Input contract policy-->

        </div>
        <!--End Card-->

        <!--Side Detail-->
        <div class="w-full border border-t-1 border-gray-200 bg-white px-4 py-4 md:px-8 md:py-8">
            <div v-if="category=='ahorro' || category=='credito' || category=='inscripcion'" class="w-full text-md md:text-2xl font-bold text-black mb-3 md:mt-5 md:mb-10 detail-title-primary">Detalles</div>
            <div v-if="category=='vida-simple' || category=='accidentes-enfermedades'" class="w-full text-md md:text-2xl font-bold text-black mb-3 md:mt-5 md:mb-10 detail-title-primary">Detalles de Producto</div>
            
            <!--Detail saving-->
            <div v-if="category=='ahorro' || category=='inscripcion'">
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Número de aportaciones</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ detail.number_of_payments }}</div>
                </div>
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Fecha de última aportacion</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ $filters.formatDateComplete(detail.date_of_last_payment) }}</div>
                </div>
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Fecha de primera aportacion</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ $filters.formatDateComplete(detail.date_of_first_payment) }}</div>
                </div>
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Tipo de Aportación</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ $filters.formatCamelize(detail.collection_method) }}</div>
                </div>

                <!--Input History-->
                <div class="w-full grid grid-cols-2 mb-3 text-center px-1 py-6 md:py-12 hidden md:block">
                    <button @click="getHistory()" class="btn-history border-2 rounded px-16 py-3 coursor-pointer md:w-full">Historial de Aportaciones</button>
                </div>
                <!--End Input History-->

                <hr class="block md:hidden my-5">

                <!--History-->
                <div v-show="showTableHistory" class="overflow-y-scroll overflow-x-scroll h-80" >
                    <table class="min-w-full border-collapse">
                        <thead class="sticky top-0">
                          <tr>
                            <th class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Número de pago</th>
                            <th class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Aportación</th>
                            <th class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Conducto de pago</th>
                            <th class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Frecuencia</th>
                            <th class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Periodo</th>
                            <th class="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registrado el</th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-show="history.length == 0"> 
                                <td class="px-6 py-4 text-center" colspan="6">No hay información para mostrar </td>
                            </tr>
                            <tr v-for="(register, index) in history" :key="index">
                                <td class="px-6 py-4 whitespace-nowrap">{{ index+1 }}</td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ $filters.formatCurrencyWithDecimal(register.total_amount_cents/100) }}</td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ $filters.formatCapitalize(register.collection_method) }}</td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ $filters.formatCapitalize(register.collection_frequency) }}</td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ register.year_and_period }}</td>
                                <td class="px-6 py-4 whitespace-nowrap">{{ $filters.formatDate(register.created_at) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--End History-->
            </div>
            <!--End Detail Saving-->

            <!--Detail Credit-->
            <div v-if="category=='credito'" >
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Número de aportaciones</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ detail.number_of_payments }}</div>
                </div>
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Fecha de última aportacion</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ $filters.formatDateComplete(detail.date_of_last_payment) }}</div>
                </div>
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Fecha de primera aportacion</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ $filters.formatDateComplete(detail.date_of_first_payment) }}</div>
                </div>
                <div class="w-full grid grid-cols-2 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">Tipo de Aportación</div>
                    <div class="detail-secondary-text text-sm md:text-md font-normal md:font-semibold text-black detail-subtitle-value-primary">{{ $filters.formatCamelize(detail.collection_method) }}</div>
                </div>
            </div>
            <!--End Detail Credit-->

            <!--Detail Vida Simple && Accidentes y enfermedades -->
            <div v-if="category=='accidentes-enfermedades' || category=='vida-simple'" >
                <div class="w-full grid grid-cols-1 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">1. Detalle 1</div>
                </div>
                <div class="w-full grid grid-cols-1 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">2. Detalle 2</div>
                </div>
                <div class="w-full grid grid-cols-1 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">3. Detalle 3</div>
                </div>
                <div class="w-full grid grid-cols-1 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">4. Detalle 4</div>
                </div>
                <div class="w-full grid grid-cols-1 mb-3 md:mt-6 md:mb-6">
                    <div class="detail-primary-text text-sm md:text-md font-light md:font-thin text-gray-400 pr-4 md:pr-16 leading-4 detail-subtitle-primary">5. Detalle 5</div>
                </div>
                
                <!--Input History-->
                <div class="w-full grid grid-cols-2 mb-3 text-center px-1 py-6 md:py-12 hidden md:block">
                    <button @click="getHistory()" class=" btn-history border-2 rounded px-16 py-3 coursor-pointer md:w-full">Historial de Aportaciones</button>
                </div>
                <!--End History-->

            
            </div>
            <!--End Detail Credit-->
            
        </div>
        
        <!--Input History-->
        <div v-if="category=='ahorro' || category == 'vida-simple'" class="sticky bottom-0  bg-white w-full text-center border border-b-1 md:border-b-0 border-gray-200 px-1 py-6 md:hidden">
            <button @click="getHistory()" class=" btn-history border-2 rounded px-16 py-3 coursor-pointer">Historial de Aportaciones</button>
        </div>
        <!--End History-->

        <!--End Side Detail-->

    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onBeforeMount, getCurrentInstance } from 'vue';

import Saving from '@/classes/Saving.js';
import { getTokenDecoden } from '@/helpers/tokenauth.js';

import Loading from "@/components/Loading/VueLoading.vue";
import Swal from 'sweetalert2';

import { create_array_structure, get_information_benefit, sumTotalSavings } from "@/helpers/clientBenefits.js"

export default {
    components: {
        Loading
    },
    setup(){
        let savingClass = ref(null);
        let clientId = ref(null);
        let isLoading = ref(true);
        let detail = ref({
            "amount_cents": 0,
            "collection_method": "",
            "current_balance_cents": 0,
            "date_of_first_payment": "",
            "date_of_last_payment": "",
            "foncabsa_client_id": 0,
            "id": 0,
            "number_of_payments": 0
        });
        let app = getCurrentInstance();


        const category = ref(null);
        const router = useRouter();
        const history = ref([]);
        const showTableHistory = ref(false);

        onBeforeMount(async ()=>{
            app = app.appContext.config.globalProperties;
            savingClass.value = new Saving();
            loadInfo();
            
            category.value = router.currentRoute.value.params.category;
            switch (category.value) {
                case 'ahorro':
                    await getBasicSummary("foncabsatemporalsaving");
                    break;
                case 'inscripcion':
                    await getBasicSummary('foncabsatemporalenrollment');
                    break;
                default:
                    isLoading.value = false;
                    break;
            }
        });

        async function getHistory(){
            showTableHistory.value = !showTableHistory.value;
            if(!showTableHistory.value) return;
            
            isLoading.value = true;
            history.value = [];
            const response = await getPaymentHistory().finally(()=> { setTimeout(() => { isLoading.value = false;}, 500); });

            if(response == null){
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', app.$filters.formatCapitalize(response.message));
                return;
            }
            
            history.value = response.data;
        }

        function showMessage(type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B"){
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            });
        }

        function loadInfo() {
            var auth = getTokenDecoden();
            clientId.value = auth.obj.person.client_id;
        }

        async function getBasicSummary(benefitKey) {
            
            isLoading.value = true;
            let savings = await getBenefitByBenefitKey(benefitKey);
            if(savings !== null && !isEmptyObject(savings)){
                let currentBalanceCents = 0;
                let amountCents = 0;
                if(benefitKey == "foncabsatemporalsaving"){
                    let current_balance_cents = savings.current_balance_cents;
                    let savings_yield_cents = savings.savings_yield_cents;

                    let sumTotalSaving = sumTotalSavings(current_balance_cents, savings_yield_cents);
                    currentBalanceCents = sumTotalSaving / 100;
                }else{
                    currentBalanceCents = savings.current_balance_cents / 100;
                }

                amountCents = savings.amount_cents / 100;

                detail.value = {
                    "amount_cents": (savings.amount_cents!=undefined)? amountCents: 0,
                    "collection_method": (savings.collection_method!=undefined)? savings.collection_method: '',
                    "current_balance_cents": (savings.current_balance_cents!=undefined)? currentBalanceCents: 0,
                    "date_of_first_payment": (savings.amount_cents!=undefined)?savings.date_of_first_payment: '',
                    "date_of_last_payment": (savings.amount_cents!=undefined)?savings.date_of_last_payment: '',
                    "foncabsa_client_id": (savings.amount_cents!=undefined)?savings.foncabsa_client_id: 0,
                    "id": (savings.amount_cents!=undefined)?savings.id: 0,
                    "number_of_payments": (savings.amount_cents!=undefined)?savings.number_of_payments: ''
                };
                
            }else{
                Swal.fire({
                    icon:"info",
                    title:"Aviso",
                    text: "No se ha encontrado información sobre su ahorro.",
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FFFFFF'
                });
            }
            isLoading.value = false; 
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        async function getBenefitByBenefitKey(benefitKey){
            let benefitsList = await savingClass.value.getBenefitsByClientId(clientId.value).then(resp => create_array_structure(resp)).catch(err =>  null);
            if(benefitsList.length){
                let infoBenefit = await get_information_benefit(benefitKey, benefitsList);
                return infoBenefit;
            }

            return null;
        } 

        async function getPaymentHistory() {
            return await savingClass.value.getPaymentHistory(getToken(), clientId.value).then(resp => {
                return {status: true, message: resp.data.response.message, data: resp.data.response.result};
            } ).catch(err => {
                return {status: false, message: resp.data.response.message, data: resp.data.response.result};
            });
        }

        function getToken() {
            return sessionStorage.getItem("login");
        } 

        return {
            isLoading,
            detail,
            category,
            isEmptyObject,
            getHistory,
            history,
            showTableHistory
        }
    }
}
</script>

<style scoped>
.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}
.btn-history {
    color: #DEA626;
    border-color: #DEA626;
    font-family: 'Roboto';
}
.btn-contract-policy {
    border-color: #DEA626;
    color: white;
    background-color: #DEA626;
    font-family: 'Roboto';
}
.detail-container {
    background-color: transparent;
}
.detail-title {
    font-family: 'Roboto';
}
.detail-currency {
    font-family: 'RobotoBold';
}
.detail-subtitle {
    color: #857B70;
}
.detail-primary-text {
    color: #857B70;
}
.detail-primary-text {
    font-family: 'RobotoRegular';
}
.detail-title-primary {
    font-family: 'RobotoBold';
}
.detail-subtitle-primary {
    font-family: 'RobotoRegular';
}
.detail-subtitle-value-primary {
    font-family: 'Roboto';
}

@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    }
}
</style>